var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container-empty" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { style: { width: "calc(25% - 5px)" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-card",
                    { staticClass: "extendConfigCard" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cardHeader",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                [
                                  _c("title-icon"),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("tenant.list"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.tenantList.length > 0
                        ? [
                            _c("div", [
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  class: { ulStyle: _vm.isUl },
                                },
                                _vm._l(_vm.tenantList, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.tenantId,
                                      staticClass: "tenantLabel",
                                      class: { liChosed: index == _vm.current },
                                      attrs: {
                                        title: item.tenantName,
                                        label: item.tenantName,
                                        value: item.tenantId,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showItDetail(item, index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.tenantName))]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]
                        : [
                            _c("div", { staticClass: "no-data" }, [
                              _vm._v(_vm._s(_vm.$t("commons.noData"))),
                            ]),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { style: { width: "calc(75% - 5px)", marginLeft: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-card", { staticClass: "extendConfigCard" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardHeader",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("title-icon"),
                        _c("span", [_vm._v(_vm._s(_vm.configTitle))]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: { model: _vm.form, "label-width": "165px" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "vlink.commons.maxProduct"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            size: "small",
                                            maxlength: "5",
                                            "show-word-limit": true,
                                          },
                                          model: {
                                            value: _vm.form.maxProduct,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxProduct",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxProduct",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "vlink.commons.maxDevice"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            size: "small",
                                            maxlength: "5",
                                            "show-word-limit": true,
                                          },
                                          model: {
                                            value: _vm.form.maxDevice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxDevice",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxDevice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "vlink.commons.maxCustomTopic"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            size: "small",
                                            maxlength: "5",
                                            "show-word-limit": true,
                                          },
                                          model: {
                                            value: _vm.form.maxCustomTopic,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxCustomTopic",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxCustomTopic",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "vlink.commons.maxConsumerGroup"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            size: "small",
                                            maxlength: "5",
                                            "show-word-limit": true,
                                          },
                                          model: {
                                            value: _vm.form.maxConsumerGroup,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxConsumerGroup",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxConsumerGroup",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "vlink.commons.maxRule"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input",
                                          attrs: {
                                            size: "small",
                                            maxlength: "5",
                                            "show-word-limit": true,
                                          },
                                          model: {
                                            value: _vm.form.maxRule,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "maxRule", $$v)
                                            },
                                            expression: "form.maxRule",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { offset: 1 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveExtendConfig },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("commons.save")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }