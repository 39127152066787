<template>
    <div class="app-container-empty">
        <el-row>
            <el-col :style="{width:'calc(25% - 5px)'}">
                <el-row>
                    <el-card class="extendConfigCard">
                        <div slot="header" class="cardHeader">
                            <el-row>
                                <el-col>
                                    <title-icon/><span>{{ $t('tenant.list') }}</span>
                                </el-col>
                            </el-row>
                        </div>
                        <template v-if="tenantList.length > 0">
                            <div>
                                <ul :class="{ulStyle:isUl}" v-loading="loading">
                                    <li
                                            :class="{liChosed:index==current}"
                                            v-for="(item,index) in tenantList"
                                            class="tenantLabel"
                                            :title="item.tenantName"
                                            :label="item.tenantName"
                                            :value="item.tenantId"
                                            :key="item.tenantId"
                                            @click="showItDetail(item,index)">{{ item.tenantName }}</li>
                                </ul>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-data">{{$t('commons.noData')}}</div>
                        </template>
                    </el-card>
                </el-row>
            </el-col>
            <el-col :style="{width:'calc(75% - 5px)',marginLeft:'10px'}">
                <el-row>
                    <el-card class="extendConfigCard">
                        <div slot="header" class="cardHeader">
                            <title-icon/><span>{{configTitle}}</span>
                        </div>
                        <div>
                            <el-form ref="form" :model="form" label-width="165px">
                                <el-row>
                                    <el-col>
                                        <el-form-item :label="$t('vlink.commons.maxProduct')">
                                            <el-input class="input" size="small" maxlength="5" :show-word-limit="true" v-model="form.maxProduct"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item :label="$t('vlink.commons.maxDevice')">
                                            <el-input class="input" size="small" maxlength="5" :show-word-limit="true" v-model="form.maxDevice"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item :label="$t('vlink.commons.maxCustomTopic')">
                                            <el-input class="input" size="small" maxlength="5" :show-word-limit="true" v-model="form.maxCustomTopic"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item :label="$t('vlink.commons.maxConsumerGroup')">
                                            <el-input class="input" size="small" maxlength="5" :show-word-limit="true" v-model="form.maxConsumerGroup"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item :label="$t('vlink.commons.maxRule')">
                                            <el-input class="input" size="small" maxlength="5" :show-word-limit="true" v-model="form.maxRule"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <el-row>
                                <el-col :offset="1">
                                    <el-button type="primary" @click="saveExtendConfig">{{ $t('commons.save') }}</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import{
        getTenantList,
        getExtendConfigList,
        saveExtendConfig
    }from '@/api/ruge/tenant/extendConfig'

    export default {
        name: "ExtendConfig",
        data(){
            return{
                loading: false,
                current:0,
                isTrue:true,
                isSpan:true,
                isUl:true,
                isLi:false,
                tenantList: [],
                configTitle:null,
                form: {
                    tenantId: null,
                    maxProduct: null,
                    maxDevice: null,
                    maxCustomTopic: null,
                    maxConsumerGroup: null,
                    maxRule: null
                },
            }
        },
        created(){
            // 查询租户列表
            this.getTenantList();
        },
        methods:{
            getTenantList(){
                this.loading = true;
                getTenantList().then(res=>{
                    // 移除平台管理租户
                    res.forEach(function (itme,index,array) {
                        if(itme.tenantId === -1){
                            res.splice(index,1);
                        }
                    });
                    this.tenantList = res;
                    this.showItDetail(this.tenantList[0],0)
                }).finally(() => {
                    this.loading = false;
                })
            },
            // 显示扩展配置详情
            showItDetail(obj,index){
                if(obj != undefined){
                    getExtendConfigList({"tenantId" : obj.tenantId}).then(res=>{
                        this.clearExtendConfig();
                        this.form.tenantId = obj.tenantId;
                        this.configTitle = obj.tenantName;
                        res.forEach(v => {
                            switch (v.extendConfigType) {
                                case 'maxProduct':
                                    this.form.maxProduct = v.extendConfigValue;
                                    break;
                                case 'maxDevice':
                                    this.form.maxDevice = v.extendConfigValue;
                                    break;
                                case 'maxCustomTopic':
                                    this.form.maxCustomTopic = v.extendConfigValue;
                                    break;
                                case 'maxConsumerGroup':
                                    this.form.maxConsumerGroup = v.extendConfigValue;
                                    break;
                                case 'maxRule':
                                    this.form.maxRule = v.extendConfigValue;
                                    break;
                                default:
                                    break;
                            }
                        });
                        this.current = index;
                    });
                }
            },
            // 保存租户扩展配置
            saveExtendConfig(){
                const tenantId = this.form.tenantId;
                const obj = this.form;
                let extendConfigList = [];
                for(let key in obj){
                    // 排除租户ID属性
                    if(key != 'tenantId'){
                        extendConfigList.push({
                            tenantId: tenantId,
                            extendConfigType: key,
                            extendConfigValue: obj[key]
                        });
                    }
                }
                let params = {
                    tenantId: tenantId,
                    extendConfigList: extendConfigList
                };
                saveExtendConfig(params).then(()=>{
                    // 成功提示
                    this.$message({
                        message: this.$t('message.operationSuccess'),
                        type: 'success'
                    });
                })
            },
            clearExtendConfig(){
                this.form = {
                    tenantId: null,
                    maxProduct: null,
                    maxDevice: null,
                    maxCustomTopic: null,
                    maxConsumerGroup: null,
                    maxRule: null
                }
            }
        }

    }
</script>

<style scoped>

    .extendConfigCard >>> .el-card__header {
        padding: 6px 10px;
    }
    .extendConfigCard >>> .el-card__body {
        height: calc(100vh - 140px);
        overflow: scroll;
    }

    .cardHeader{
        line-height: 34px;
        font-weight: 600;
    }

    .extendConfigCard >>> .el-form-item__content{
        padding-left: 20px;
    }

    .ulStyle{
        padding: 0;
        margin: 0;
    }
    .ulStyle li{
        list-style: none;
        line-height: 32px;
        font-size: 14px;
        padding-left: 10px;
        cursor: pointer;
    }
    .liChosed{
        background-color: #8bc1fc21;
        font-weight: 500;
        color: #409eff;
        border-radius: 5px;
    }
    .input{
        width:100px;
    }
    .tenantLabel{
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
</style>
